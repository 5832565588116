var crud = function(options) {

	var crud = this;

	this.successDelete = function (data, reload, href, callback, hideLoader) {
		if(data.response) {
			if(reload) app.reload();
			if(href) app.href(href);
		} else {
			app.warning("", data.message);
		}
		if(callback){
			window[callback](data);
		}
		if(!hideLoader)
			app.block(0);
	};

	this.successSave = function (form, data, reload, href, callback, hideLoader, loadItem) {
		if(data.response) {
			app.success("", "Saved!");
			if(reload) app.reload();
			if(href) app.href(href +"/"+ data.message + "/edit");
		} else {
			app.warning("", data.message);
		}
		if(callback){
			var fnz = app.eval(callback);
			fnz(data);
		}
		if(!hideLoader)
			app.block(0);
	};

	this.bindEvents = function() {
		$('[data-interaction=delete]').unbind('click').bind('click', function(e) {
			e.preventDefault();

			if(confirm("Sei sicuro?")) {
				var url = $(this).attr('data-url') || ($("#" + options.form).attr("action") + "/" + $("#" + options.form).attr("data-id"));
				var error = $(this).attr('data-error');
				var reload = $(this).attr('data-reload');
				var href = $(this).attr('data-href');
				var callback = $(this).attr('data-callback');
				var hideLoader = $(this).attr('data-hideloader')||false;

				if(!hideLoader)
					app.block(1);
				$.delete(url)
					.success(function(data) {
						crud.successDelete(data, reload, href, callback, hideLoader);
					})
					.error(function() {
						if(!hideLoader)
							app.block(0);
						app.error('', 'Delete error!');
					});
			}
		});

		$('[data-interaction=save]').unbind('click').bind('click', function(e) {
			e.preventDefault();

			var error = $(this).attr('data-error');
			var reload = $(this).attr('data-reload');
			var href = $(this).attr('data-href');
			var hideLoader = $(this).attr('data-hideloader')||false;
			var callback = $(this).attr('data-callback');

			if(!hideLoader)
				app.block(1);
			app.formSubmit($("#" + options.form).attr("action"), $("#" + options.form), {}, function(data) {
				crud.successSave($("#" + options.form), data, reload, href, callback, hideLoader);
			}, function() {
				app.error("", error);
				if(!hideLoader)
					app.block(0);
			});
		});

		$('[data-interaction="save-top"]').unbind('click').bind('click', function () {
			var target = $(this).attr('data-target');

			$("#" + target).find('[data-interaction=save]').trigger('click');
		});

		$('[data-interaction="delete-top"]').unbind('click').bind('click', function () {
			var target = $(this).attr('data-target');

			$("#" + target).find('[data-interaction=delete]').trigger('click');
		});
	};

	this.bindEvents();
};