/**
 * This plug-in for DataTables represents the ultimate option in extensibility
 * for sorting date / time strings correctly. It uses
 * [Moment.js](http://momentjs.com) to create automatic type detection and
 * sorting plug-ins for DataTables based on a given format. This way, DataTables
 * will automatically detect your temporal information and sort it correctly.
 *
 * For usage instructions, please see the DataTables blog
 * post that [introduces it](//datatables.net/blog/2014-12-18).
 *
 * @name Ultimate Date / Time sorting
 * @summary Sort date and time in any format using Moment.js
 * @author [Allan Jardine](//datatables.net)
 * @depends DataTables 1.10+, Moment.js 1.7+
 *
 * @example
 *    $.fn.dataTable.moment( 'HH:mm MMM D, YY' );
 *    $.fn.dataTable.moment( 'dddd, MMMM Do, YYYY' );
 *
 *    $('#example').DataTable();
 *
(function (factory) {
    if (typeof define === "function" && define.amd) {
        define(["jquery", "moment", "datatables"], factory);
    } else {
        factory(jQuery, moment);
    }
}(function ($, moment) {

    $.fn.dataTable.moment = function (format, locale) {
        var types = $.fn.dataTable.ext.type;

        // Add type detection
        types.detect.unshift(function (d) {
            // Strip HTML tags if possible
            if (d && d.replace) {
                d = d.replace(/<.*?>/g, '');
            }

            // Null and empty values are acceptable
            if (d === '' || d === null) {
                return 'moment-' + format;
            }

            return moment(d, format, locale, true).isValid() ?
            'moment-' + format :
                null;
        });

        // Add sorting method - use an integer for the sorting
        types.order['moment-' + format + '-pre'] = function (d) {
            if (d && d.replace) {
                d = d.replace(/<.*?>/g, '');
            }
            return d === '' || d === null ?
                -Infinity :
                parseInt(moment(d, format, locale, true).format('x'), 10);
        };
    };

}));

var debug = null;
var dataTable = function (options) {

    if (!options) {
        console.error("Specify options!");
        return;
    }

    var dataTable = this;

    /**
     * Oggetto contenente le traduzioni
     * /
    this.T = {
        askDelete: undefined
    };

    this.idTable = "";
    this.subTableTemplate = "subTableTemplate";
    this.subBodyTemplate = "subBodyTemplate";
    this.table = undefined;

    this.fixedHeader = options.fixedHeader || false;
    this.csv = options.csv || false;
    this.pdf = options.pdf || false;
    this.idTable = options.idTable || "";
    this.columns = options.columns || undefined;
    this.order = options.order || undefined;
    this.searchCols = options.searchCols || undefined;
    this.filterColumns = options.filterColumns || undefined;
    this.lenghtMenu = options.lenghtMenu || [[10, 50, 100, -1], ["10", "50", "100", "---"]];
    this.footerCallback = options.footerCallback || function () {};
    this.pagination = (typeof options.pagination != 'undefined') ? options.pagination : true;

    // indica se c'è una subgrid / edit o add inline aperto come row
    this.inlineOpen = false;

    this.$table = $("#" + dataTable.idTable);

    // se visualizzare o meno il block ui durante la fase di processing
    this.blockUI = (typeof options.blockUI != 'undefined' && options.blockUI) ? true : false;
    this.$table.on('processing.dt', function (a, b, processing) {
        if (dataTable.blockUI)
            app.blockUI(processing);
    });

    // titolo stampato
    this.title = this.$table.attr("data-dt-title");
    this.displayStart = this.$table.attr("data-dt-start");
    this.pageLength = this.$table.attr("data-dt-length");
    if (!this.title) this.title = "";

    // nome della configurazione
    this.configuration = this.$table.attr("data-dt-configuration");

    // nome della variabile in sessione per i parametri custom della tabella
    this.dt_session = this.$table.attr("data-dt-session") || undefined;

    // url per server processing e edit|delete
    this.baseUrl = this.$table.attr("data-dt-url");
    this.ajaxUrl = this.baseUrl + "/dt?dt=" + this.configuration + ((typeof this.dt_session != 'undefined') ? "&dt_session=" + this.dt_session : "");
    this.ajaxEditUrl = this.baseUrl + "/dt?op=inline&dt=" + this.configuration + ((typeof this.dt_session != 'undefined') ? "&dt_session=" + this.dt_session : "");

    this.ajaxUrlParameters = this.$table.attr("data-dt-url-parameters");

    // array di oggetti per i custom buttons. Di default viene gestito in automatico solo add
    this.customButtons = [];

    this.scrollY = options.scrollY || undefined;
    this.scrollX = options.scrollY || undefined;

    // funzione per customizzare la stampa pdf
    this.printCallback = function (doc) {
        var colCount = [];
        dataTable.$table.find('tbody tr:first-child td').each(function () {
            if ($(this).attr('colspan')) {
                for (var i = 1; i <= $(this).attr('colspan'); i++) {
                    colCount.push('*');
                }
            } else {
                colCount.push('*');
            }
        });
        if (doc.content[1].table) {
            doc.content[1].table.widths = colCount;
        }
    };
    // override funzione stampa
    if (window[this.$table.attr("data-dt-print")] == 'function') this.printCallback = this.$table.attr("data-dt-print");

    // funzione per customizzare l'export
    this.exportCallback = this.$table.attr("data-dt-export");

    // serve per mettere il focus su un elemento una volta completato il redraw
    this.focusAfterDraw = undefined;

    this.nInlineButton = 0;

    // funzione che crea l'array di colonne per la dataTable
    this.makeColumns = function () {
        var doSort = (typeof dataTable.columns == "undefined" || typeof dataTable.columns == "undefined");
        var doFilter = (typeof dataTable.filterColumns == "undefined" || typeof dataTable.filterColumns == "undefined");
        if (doSort) dataTable.columns = [];
        if (doSort) dataTable.order = [];
        if (doFilter) dataTable.searchCols = [];
        if (doFilter) dataTable.filterColumns = [];
        var sort = undefined;
        var filter = undefined;
        var columnIndex = undefined;

        dataTable.$table.find("thead").find("tr").find("th").each(function (index) {
            sort = $(this).attr("data-dt-sort");
            if (typeof sort != "undefined" && $.trim(sort) != "" && sort !== 1) {
                sort = $.parseJSON(sort);
            }
            filter = $(this).attr("data-dt-filter");
            if (typeof filter != "undefined")
                filter = $.parseJSON(filter);
            columnIndex = $(this).attr("data-dt-column-index");

            if (typeof $(this).attr("data-dt-inline-button") != 'undefined') {
                var colBtnVisible = false;
                var subgridParam = $(this).attr("data-dt-subgrid") || undefined;
                var buttons = "";
                if (typeof subgridParam != 'undefined') {
                    var subPar = JSON.parse(subgridParam);
                    colBtnVisible = true;
                    $.each(subPar, function (i, obj) {
                        buttons = buttons + '<button data-interaction="subgrid" data-method="' + ((typeof obj.method != "undefined") ? obj.method : "post") + '" data-url="' + obj.url + '" class="btn btn-link btn-warning"><i data-class-close="' + (obj.icon || 'fa fa-minus') + '" data-class-open="' + (obj.icon || 'fa fa-plus') + '" class="' + (obj.icon || 'fa fa-plus') + '"></i></button>';
                        dataTable.nInlineButton++;
                    });
                }
                if ($(this).attr("data-dt-inline-button") == "1") {
                    // colonna bottoni inline
                    colBtnVisible = true;
                    var buttonsConfig = $(this).attr("data-dt-button") || 'edit|delete';
                    var editUrl = $(this).attr("data-dt-edit-url") || dataTable.baseUrl;
                    var deleteUrl = $(this).attr("data-dt-delete-url") || dataTable.baseUrl;

                    $.each(buttonsConfig.split("|"), function (i, obj) {
                        var method = "get";
                        if (obj.indexOf("[") > -1) {
                            method = obj.substr(obj.indexOf("[") + 1, obj.indexOf("]") - obj.indexOf("[") - 1);
                            obj = obj.substr(0, obj.indexOf("["));
                        }
                        dataTable.nInlineButton++;
                        switch (obj) {
                            case "edit":        // TODO come gestiamo le lingue? "Edit"
                                buttons = buttons + '<a title="Edit" data-dt-inline-button="1" data-interaction="edit" href="' + editUrl + '" class="btn btn-link btn-warning"><i class="fa fa-pencil"></i></a>';
                                break;
                            case "edit_inline":
                                buttons = buttons + '<button data-method="' + method + '" title="Edit" data-dt-inline-button="1" data-interaction="edit_inline" data-url="' + editUrl + '" class="btn btn-link btn-warning"><i class="fa fa-pencil"></i></button>';
                                break;
                            case "delete":      // TODO come gestiamo le lingue? "Delete"
                                buttons = buttons + '<button title="Delete" type="button" data-dt-inline-button="1" data-reload="true" data-interaction="delete" data-url="' + deleteUrl + '" class="btn btn-link btn-danger"><i class="fa fa-trash red"></i></button>';
                                break;
                        }
                    });
                }

                dataTable.columns.push({
                    data: null,
                    defaultContent: buttons,
                    className: 'btn-inline-edit',
                    orderable: false,
                    visible: colBtnVisible,
                    createdCell: function (td, cellData) {
                        $(td).find('[data-interaction=subgrid]').attr("data-url", $(td).find('[data-interaction=subgrid]').attr("data-url") + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                        $(td).find('[data-interaction=delete]').attr("data-url", deleteUrl + "/" + cellData[0] + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                        $(td).find('[data-interaction=edit]').attr("href", editUrl + "/" + cellData[0] + "/edit" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                        $(td).find('[data-interaction=edit_inline]').attr("data-url", editUrl + "/" + cellData[0] + "/edit" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                    }
                });
                dataTable.searchCols.push(null);
            } else {
                // colonne dati
                var formatterFunction = $(this).attr("data-dt-formatterjs") || undefined;
                var align = $(this).attr("data-dt-align") || undefined;
                var editable = $(this).attr("data-editable") || undefined;

                if (doSort) {
                    var objColumn = {};

                    if (typeof sort != "undefined" && $.trim(sort) != "" && sort != "false" && sort != "0") {
                        objColumn = {"bSortable": true};
                        if (typeof sort.order != "undefined" && sort.order != null)
                            dataTable.order.push([index, sort.order.toLowerCase()]);
                    } else {
                        objColumn = {"bSortable": false};
                    }

                    if (typeof align != "undefined")
                        objColumn.sClass = "text-" + align;

                    objColumn.columnIndex = columnIndex;

                    if (formatterFunction) {
                        var formatterFunctionCallback = null;

                        try {
                            // il formatter è parte del set base dei formatter
                            if (typeof(window['formatter']) == 'object')
                                if (typeof(window['formatter'][formatterFunction]) == 'function')
                                    formatterFunctionCallback = window['formatter'][formatterFunction];

                            // il formatter è una funzione custom nella forma 'nomeFunzione' o 'oggetto.nomeFunzione'
                            if (formatterFunctionCallback == null) {
                                if (typeof(window[formatterFunction]) == 'function') {
                                    formatterFunctionCallback = window[formatterFunction];
                                } else if (formatterFunction.indexOf('.') > 0) {
                                    var fnObj = formatterFunction.split('.')[0];
                                    var fnFunction = formatterFunction.split('.')[1];

                                    if (typeof(window[fnObj][fnFunction]) == 'function') {
                                        formatterFunctionCallback = window[fnObj][fnFunction];
                                    }
                                }
                            }

                            if (typeof formatterFunctionCallback == 'function') {
                                objColumn.createdCell = function (td, cell_data, row_data, row_index, col_index) {
                                    $(td).html(formatterFunctionCallback(dataTable, td, cell_data, row_data, row_index, col_index));
                                };
                            } else {
                                console.warn("Callback '" + formatterFunction + "' not found (column index: " + index + ")");
                            }
                        } catch (err) {
                            console.warn("Callback '" + formatterFunction + "' not found (column index: " + index + ")");
                        }
                    }

                    if (editable) {
                        objColumn.editable = $.parseJSON(editable);
                    }

                    dataTable.columns.push(objColumn);
                }

                if (doFilter) {
                    if (typeof filter != "undefined" && filter != "false" && filter != "0") {
                        if (typeof filter.init_value != "undefined" && filter.init_value != null) {
                            dataTable.searchCols.push({
                                search: "" + filter.init_value.id
                            });
                        } else {
                            dataTable.searchCols.push(null);
                        }

                        dataTable.filterColumns[index] = {
                            index: index,
                            columnIndex: columnIndex,
                            filter: filter
                        };
                    }
                }
            }
        });
    };

    this.makeFilters = function () {
        var filter = dataTable.$table.find('thead').attr("data-dt-enable-filter");
        if (filter !== undefined && filter != undefined && typeof filter != "undefined" && filter != "false" && filter != "0") {
            /**
             * Ho abilitato i filtri.
             * Creo una nuova tr dove ci saranno i filtri
             * /
            var n = dataTable.$table.find("thead").find("tr").find("th").length;
            var $tr = $('<tr data-dt-filter="1"></tr>');
            for (var i = 0; i < n; i++) {
                var $th = $('<th data-filter="' + i + '">' + +'</th>');
                $tr.append($th);
            }
            var content = dataTable.$table.find("thead").html();
            dataTable.$table.find("thead").html($tr);
            dataTable.$table.find("thead").append(content);
        }
    };

    this.makeSorting = function () {
        // date
        $.fn.dataTable.moment('DD/MM/YYYY');

        // numeric
        $.fn.dataTable.ext.order['numeric'] = function (settings, col) {
            return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                var value = $(td).html().trim();
                value = value.split(",").join("");
                value = value.split("--").join("0");
                return parseFloat(value);
            });
        };

        // numeric comma
        $.fn.dataTable.ext.order['numeric-comma'] = function (settings, col) {
            return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                var value = $(td).html().trim();
                value = value.split(".").join("");
                value = value.split(",").join(".");
                value = value.split("--").join("0");
                return parseFloat(value);
            });
        };
    };

    this.DTfnBeforeDrawCallback = function (settings) {
    };
    this.DTfnDrawCallback = function (settings) {
        dataTable.DTfnBeforeDrawCallback(settings);
        dataTable.bindEvents();
        if (dataTable.focusAfterDraw !== undefined) {
            $(dataTable.table.cell(dataTable.focusAfterDraw).node()).find('.data-dt-focus').focus();
            dataTable.focusAfterDraw = undefined;
        }
        dataTable.DTfnAfterDrawCallback(settings);
    };
    this.DTfnAfterDrawCallback = function (settings) {
    };

    /**
     * Funzione da overridare
     * @constructor
     * /
    this.DTbeforeInitComplete = function () {

    };
    /**
     * Funzione da overridare
     * @constructor
     * /
    this.DTafterInitComplete = function () {

    };

    this.DTinitComplete = function (settings, json) {
        dataTable.DTbeforeInitComplete();

        this.api().columns().every(function (i) {
            var filterColumn = dataTable.filterColumns[i];
            if (filterColumn) {
                var column = this;
                var $search = undefined;
                var $from = undefined;
                var $to = undefined;
                var $appendTo = $(this.header()).parent().parent().find("tr:first").find("[data-filter='" + i + "']");

                var filter = filterColumn.filter;
                var type = filter.type;
                if (typeof type == 'undefined' || type == null || type.trim() == '')
                    type = 'select';

                switch (type) {
                    case "date":
                    case "datetime":
                    case "datesingle":
                    case "datetimesingle":
                        var c = 'datepicker';
                        var place = 'Da';
                        if (filter.type == 'datetime' || filter.type == 'datetimesingle') c = 'datetimepicker';
                        if (filter.type == 'datetimesingle' || filter.type == 'datesingle') place = "";
                        $from = $('<input type="text" class="' + c + ' dtfrom" placeholder="' + place + '">');
                        var $calendar = $('<span class="input-group-addon"><i class="fa fa-calendar"></i></span>');
                        var $fromWrapper = $('<div class="input-group dtDateRangeWrapper"></div>');
                        $fromWrapper.append($calendar);
                        $fromWrapper.append($from);
                        $fromWrapper.appendTo($appendTo.empty());
                        var format = 'd/m/Y';
                        if (filter.type == 'datetime') format = 'd/m/Y H:i:s';
                        $from.datetimepicker({
                            dayOfWeekStart: 1,
                            // mask: true,
                            format: format,
                            closeOnDateSelect: true,
                            timepicker: false,
                            scrollInput: false
                        });
                        $from.on('change', function () {
                            var from = $(this).val();
                            if (filter.type == 'datesingle' || filter.type == 'datetimesingle') {
                                column.search(from ? from : '', true, false).draw();
                            } else {
                                var to = $(this).closest('th').find('.dtto').val();
                                from = from ? from : "";
                                to = to ? to : "";
                                column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                            }
                        });

                        if (filter.type == "date" || filter.type == "datetime") {
                            place = 'A';
                            if (filter.type == 'datetimesingle' || filter.type == 'datesingle') place = "";
                            $to = $('<input type="text" class="' + c + ' dtto" placeholder="' + place + '">');
                            $calendar = $('<span class="input-group-addon"><i class="fa fa-calendar"></i></span>');
                            var $toWrapper = $('<div class="input-group dtDateRangeWrapper"></div>');
                            $toWrapper.append($calendar);
                            $toWrapper.append($to);
                            $toWrapper.appendTo($appendTo);
                            $to.datetimepicker({
                                dayOfWeekStart: 1,
                                // mask: true,
                                format: format,
                                closeOnDateSelect: true,
                                timepicker: false,
                                scrollInput: false
                            });
                            $to.on('change', function () {
                                var to = $(this).val();
                                var from = $(this).closest('th').find('.dtfrom').val();
                                from = from ? from : "";
                                to = to ? to : "";
                                column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                            });
                        }
                        break;
                    case "number":
                        $from = $('<input placeholder="Da" type="text" class="dtfrom" style="width: 100%;">'); // TODO gestione lingue
                        $from.appendTo($appendTo.empty());
                        $from.on('click', function (e) {
                            e.stopPropagation();
                        });
                        $from.on('change', function () {
                            var from = $(this).val();
                            var to = $(this).closest('th').find('.dtto').val();
                            from = from ? from : "";
                            to = to ? to : "";
                            column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                        });

                        $to = $('<input placeholder="A" type="text" class="dtto" style="width: 100%;">'); // TODO gestione lingue
                        $to.appendTo($appendTo);
                        $to.on('change', function () {
                            var to = $(this).val();
                            var from = $(this).closest('th').find('.dtfrom').val();
                            from = from ? from : "";
                            to = to ? to : "";
                            column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                        });
                        break;
                    case 'select':
                        $search = $('<select class="select2" style="width: 100%;" data-placeholder=" "><option value=""></option></select>')
                            .appendTo($appendTo.empty())
                            .on('change', function () {
                                var val = $(this).val();
                                column.search(val ? val : '', true, false).draw();
                            });

                        // gestione valore iniziale
                        column.data().unique().sort().each(function (d) {
                            if (filter.init_value) {
                                $search.append('<option selected value="' + filter.init_value.id + '">' + filter.init_value.text + '</option>');
                            }
                        });

                        var columnIndex = filterColumn.columnIndex;

                        var url = filter.url || dataTable.ajaxUrl;

                        if (typeof columnIndex != 'undefined') {
                            // creo l'url che verrà contattata dalla select2 per recuperare i dati
                            if (url.indexOf("?") > -1) {
                                url = url + "&op=autocomplete&dt_col_index=" + columnIndex + (dataTable.ajaxUrlParameters ? "&" + dataTable.ajaxUrlParameters : '');
                            } else {
                                url = url + "?op=autocomplete&dt_col_index=" + columnIndex + (dataTable.ajaxUrlParameters ? "&" + dataTable.ajaxUrlParameters : '');
                            }

                            // template di visualizzazione dei risultati della select2
                            function templateResult(data) {
                                return data.text;
                            }

                            // template di visualizzazione del risultato selezionato della select2
                            function templateSelection(data) {
                                if (typeof data.label == "undefined")
                                    data.label = data.text;
                                return data.label;
                            }

                            var minimumInputLength = 0;
                            if (typeof filter.preload != "undefined" && filter.preload == false)
                                minimumInputLength = 1;

                            $search.select2({
                                ajax: {
                                    url: url,
                                    dataType: 'json',
                                    delay: 100,
                                    type: 'POST',
                                    data: function (params) {
                                        return {
                                            search: params.term, // search term
                                            page: params.page
                                        };
                                    },
                                    processResults: function (data, params) {
                                        // parse the results into the format expected by Select2
                                        // since we are using custom formatting functions we do not need to
                                        // alter the remote JSON data, except to indicate that infinite
                                        // scrolling can be used
                                        params.page = params.page || 1;

                                        return {
                                            results: data.items
                                        };
                                    },
                                    cache: true
                                },
                                escapeMarkup: function (markup) {
                                    return markup;
                                },
                                minimumInputLength: minimumInputLength,
                                allowClear: true,
                                cache: true,
                                templateResult: templateResult,
                                templateSelection: templateSelection
                            }).on("select2:unselecting", function (e) {
                                $(this).data('state', 'unselected');
                            });
                        }
                        break;
                }
            }
        });

        dataTable.DTafterInitComplete();
    };

    this.DTfnCreatedRow = function (nRow, aData) {
        $(nRow).attr('data-id', aData[0]);
        $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        $(nRow).children("td").css("text-overflow", "ellipsis");

        // per ogni cella forzo la max-width della th relativa
        var index = 0;
        $(nRow).find("td").each(function () {
            if (typeof dataTable.columnDefs[index] != "undefined" && typeof dataTable.columnDefs[index].width != "undefined")
                $(this).css("max-width", dataTable.columnDefs[index].width);
            index++;
        });
    };

    this.afterEditInline = function (dt, $row, data) {
        var c = new crud({
            form: $row.find("form").attr("id")
        });

        c.successSave = function (data, reload, href, callback, hideLoader) {
            if(data.response) {
                app.success("", "Salvato!");
                dataTable.table.draw("page");
                dataTable.inlineOpen = false;
            } else {
                app.warning("", data.message);
            }
            if(callback){
                var fnz = app.eval(callback);
                fnz(data);
            }
            if(!hideLoader)
                app.block(0);
        };
        c.successDelete = function (data, reload, href, callback, hideLoader) {
            if(data.response) {
                dataTable.table.draw("page");
                dataTable.inlineOpen = false;
            } else {
                app.warning("", data.message);
            }
            if(callback){
                window[callback](data);
            }
            if(!hideLoader)
                app.block(0);
        };
        $row.find('[data-interaction=cancel]').unbind('click').bind('click', function (e) {
            e.preventDefault();

            $row.hide();

            dataTable.inlineOpen = false;
        });
    };

    this.bindEvents = function () {
        // si assicura che le colonne con i filtri siano allineate con le colonne visualizzate
        // questo perchè con responsive: true alcune colonne vengono nascoste così vado a nascondere anche i filtri correlati
        var onResize = function () {
            if (dataTable.table) {
                dataTable.table.columns().every(function (i) {
                    var $th = $(this.header()).parent().parent().find("tr:first").find("[data-filter='" + i + "']");
                    if (!$(this.header()).is(":visible")) {
                        $th.hide();
                    } else {
                        $th.show();
                    }
                });
            }
        };
        var resize = undefined;
        $(window).resize(function () {
            clearTimeout(resize);
            resize = setTimeout(function () {
                /* dataTable.table.draw('page'); se scrollX abilitato * /
                onResize();
            }, 250);
        });

        setTimeout(function () {
            onResize();
        }, 250);

        dataTable.$table.find('thead').find('select').on('change', function () {
            dataTable.bindEvents();
        });

        dataTable.$table.find('select[name="ordersTable_length"]').on('change', function () {
            dataTable.bindEvents();
        });

        dataTable.$table.find(".paginate_button").click(function () {
            dataTable.bindEvents();
        });

        dataTable.$table.find('[data-interaction="edit_inline"]').unbind("click").bind("click", function () {
            var url = $(this).attr("data-url");
            var method = $(this).attr("data-method");
            var row = dataTable.table.row($(this).closest('tr'));

            if (row.child.isShown()) {
                row.child.hide();
                dataTable.inlineOpen = false;
            } else {
                if (dataTable.inlineOpen == false) {
                    $[method](url)
                        .done(function (data) {
                            var child = row.child(data);
                            child.show();
                            app.runBind();
                            dataTable.inlineOpen = true;
                            dataTable.afterEditInline(dataTable, $(row.child()[0]), data);
                        })
                        .fail(function () {
                            app.error("", "Errore server!");
                        });
                }
            }
        });

        dataTable.$table.find('[data-interaction="subgrid"]').unbind("click").bind("click", function () {

            var objI = $(this).find('i');
            if (objI.hasClass(objI.attr('data-class-open'))) {
                objI.removeClass(objI.attr('data-class-open'));
                objI.addClass(objI.attr('data-class-close'));
            } else {
                objI.removeClass(objI.attr('data-class-close'));
                objI.addClass(objI.attr('data-class-open'));
            }

            var $tr = $(this).closest('tr');
            var id = $(this).attr("data-id") || $(this).closest('tr').attr('data-id');  // FIXME dedyrt
            var url = $(this).attr("data-url");
            var dt = $(this).closest('table').attr('data-dt-configuration');
            var method = $(this).attr("data-method");
            var callbackName = $(this).attr("data-callback");
            var row = dataTable.table.row($tr);

            // verifico se la callback esiste
            var callback = false;
            if (typeof callbackName != 'undefined') {
                if (jQuery.isFunction(callbackName))
                    callback = true;
                if (jQuery.isFunction(eval(callbackName)))
                    callback = true;
            }

            if (row.child.isShown()) {
                row.child.hide();
                $tr.removeClass('shown');
            } else {
                app.block(1);
                $[method](url, {id: id, dt: 'default_' + id}) // FIXME dirt
                    .success(function (data) {
                        if (data) {
                            if (callback) {
                                eval(callbackName)(row, data);
                            } else {
                                row.child(data).show();
                                $tr.addClass('shown');
                            }
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                    });
            }
        });

        dataTable.$table.find('[data-dt-inline-button="1"][data-interaction=delete]').unbind('click').bind('click', function () {
            var url = $(this).attr('data-url');
            var conferma = dataTable.T.askDelete || 'Eliminare la riga selezionata?';

            var objButton = $(this);

            // formattazione stringa richiesta parsando le {}
            $.each(dataTable.columns, function (index, obj) {
                if (typeof obj.field != 'undefined') {
                    if (conferma.indexOf("{" + obj.field + "}") != -1) {

                        var idRiga = objButton.parents("tr[data-id]").attr("data-id");
                        var valInCell = dataTable.table.cell("[data-id=" + idRiga + "] td:eq(" + index + ")").data();

                        // recupero il campo nella colonna alla riga attuale
                        conferma = conferma.replace("{" + obj.field + "}", valInCell);
                    }
                }
            });

            if (confirm(conferma)) {
                app.block(1);
                $.delete(url)
                    .success(function (data) {
                        if (data.response) {
                            dataTable.table.draw('page');
                        } else {
                            app.warning("", data.message);
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                        app.error('', 'Delete error!');
                    });
            }
        });

        this.afterBindEvents();
    };

    this.afterBindEvents = function () {
    };

    this.makeCustomButtons = function () {
        var buttons = dataTable.$table.attr("data-dt-custom-button");
        if (buttons && buttons.trim() != "") {
            $.each(buttons.split("|"), function (index, obj) {
                var method = "get";
                if (obj.indexOf("[") > -1) {
                    method = obj.substr(obj.indexOf("[") + 1, obj.indexOf("]") - obj.indexOf("[") - 1);
                    obj = obj.substr(0, obj.indexOf("["));
                }
                switch (obj) {
                    case 'add':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-plus"></i> Aggiungi',
                                className: "btn btn-white btn-success btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    app.href(dataTable.baseUrl + "/create" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                                }
                            };
                        }
                        break;
                    case 'add_inline':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-plus"></i> Aggiungi',
                                className: "btn btn-white btn-success btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    var url = dataTable.baseUrl + "/create" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : '');

                                    // inserisco al posto del messaggio che dice che non ci sono dati
                                    var $row = $(dataTable.table.table().body());

                                    if (dataTable.inlineOpen == false) {
                                        $[method](url)
                                            .done(function (data) {
                                                var $tr = $('<tr></tr>');
                                                var $td = $('<td colspan="' + dataTable.columns.length + '"></td>');
                                                $td.html(data);
                                                $tr.html($td);
                                                $row.prepend($tr);
                                                app.runBind();
                                                dataTable.inlineOpen = true;
                                                dataTable.afterEditInline(dataTable, $tr, data);
                                            })
                                            .fail(function () {
                                                app.error("", "Errore server!");
                                            });
                                    }
                                }
                            };
                        }
                        break;
                    case 'clear':
                    case 'forget':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-eraser"></i> Pulisci',
                                className: "btn btn-white btn-warning btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    app.block(1);
                                    $.post(dataTable.ajaxUrl + "&op=forget")
                                        .done(function (data) {
                                            if (data.response)
                                            // non eseguo solo il draw per evitare di risettare filtri e di esser sicuro di riscrivere la sessione
                                                app.reload();
                                            app.block(0);
                                        })
                                        .fail(function () {
                                            app.block(0);
                                            app.error("", "Errore AJAX!");
                                        });
                                }
                            };
                        }
                        break;
                    case 'refresh':
                    case 'redraw':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-refresh"></i> Aggiorna',
                                className: "btn btn-white btn-success btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    dataTable.table.draw('page');
                                }
                            };
                        }
                        break;
                }
            });
        }

        this.afterMakeCustomButtons();
    };

    this.afterMakeCustomButtons = function () {
    };

    this.makeDT = function () {
        $.fn.dataTable.ext.errMode = function ( settings, helpPage, message ) {
            // TODO: sostituire magari con un messaggio generico migliore
            app.error(message);
        };

        var language = typeof datatable_lang != 'undefined' ? datatable_lang : {
            "sEmptyTable": "Nessun dato presente nella tabella",
            "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
            "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
            "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "Visualizza _MENU_ elementi",
            "sLoadingRecords": "Caricamento...",
            "sProcessing": "Elaborazione...",
            "sSearch": "Cerca:",
            "sZeroRecords": "La ricerca non ha portato alcun risultato.",
            "oPaginate": {
                "sFirst": "Inizio",
                "sPrevious": "Precedente",
                "sNext": "Successivo",
                "sLast": "Fine"
            },
            "select": {
                "rows": {
                    "_": "You have selected %d rows",
                    "0": "Click a row to select it",
                    "1": "Only 1 row selected"
                }
            },
            "oAria": {
                "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
                "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
            }
        };

        // imposto la width delle colonne così come vengono create all'inizio così da avere la gestione della width lato controller
        dataTable.columnDefs = [];
        var index = 0;

        // width dei bottoni inline
        if (dataTable.$table.find("thead").find("tr").find("[data-dt-inline-button=1]")) {
            dataTable.$table.find("thead").find("tr").find("[data-dt-inline-button=1]").css({
                'min-width': dataTable.nInlineButton * 25 + "px",
                'width': dataTable.nInlineButton * 25 + "px"
            });
            index++;
        }

        // fisso le colonne in modo che usando i filtri queste non si ridimensionano
        dataTable.$table.find("thead").find("tr").find('[data-dt-filter]').each(function () {
            dataTable.columnDefs.push({width: $(this).width(), targets: index++, createdCell: dataTable.DTfnCreatedCell});
        });

        var options = {
            bAutoWidth: false,
            aoColumns: dataTable.columns,
            columnDefs: dataTable.columnDefs,
            displayStart: dataTable.displayStart,
            pageLength: dataTable.pageLength,
            searchCols: dataTable.searchCols,
            order: dataTable.order,
            aaSorting: [],
            aLengthMenu: dataTable.lenghtMenu,
            bLengthChange: true,
            bPaginate: dataTable.pagination,
            select: true,
            responsive: {
                details: false
            },

            // language: prende la variabile globale datatable_lang oppure valori di default
            language: language,

            // gestione server side ajax
            processing: false,
            serverSide: (typeof dataTable.ajaxUrl != 'undefined'),
            ajax: {
                url: (typeof dataTable.ajaxUrl != 'undefined') ? dataTable.ajaxUrl + (this.ajaxUrlParameters ? "&" + this.ajaxUrlParameters : '') : false,
                type: 'POST'
            },

            footerCallback: dataTable.footerCallback,
            fnDrawCallback: dataTable.DTfnDrawCallback,
            initComplete: dataTable.DTinitComplete,
            fnCreatedRow: dataTable.DTfnCreatedRow
        };

        if (typeof dataTable.scrollY != 'undefined') {
            // TODO: quando è attivo lo scroll la DT non si ridimensiona più!
            $.extend(options, dataTable.scrollY);
        }

        // DATATABLE
        dataTable.table = dataTable.$table.DataTable(options);

        if (dataTable.fixedHeader) {
            new $.fn.dataTable.FixedHeader(dataTable.table);
        }

        var buttons = [];

        $.each(Object.keys(dataTable.customButtons), function (index, obj) {
            buttons.push(dataTable.customButtons[obj]);
        });

        if (dataTable.csv) {
            if (typeof dataTable.ajaxUrl != 'undefined') {
                buttons.push({
                    text: "<i class='fa fa-file-excel-o bigger-110'></i> CSV",
                    className: "btn btn-white btn-success btn-xs",
                    action: function () {
                        var url = dataTable.ajaxUrl + "&op=export&type=csv&" + dataTable.ajaxUrlParameters;

                        var params = dataTable.table.ajax.params();
                        params.start = 0;
                        params.length = -1;

                        app.block(1);
                        $.ajax({
                            url: url,
                            method: "POST",
                            async: false,
                            data: params
                        })
                            .success(function (data) {
                                app.block(0);
                                if (data.response) {
                                    app.locationHref(data.message, true);
                                } else {
                                    app.warning("", data.message);
                                }
                            })
                            .error(function () {
                                app.block(0);
                                app.error("", "Errore esportazione CSV!");
                            });
                    }
                });
            } else {
                buttons.push({
                    extend: "csvHtml5",
                    text: "<i class='fa fa-database bigger-110 orange'></i> <span class='hidden'>Export to CSV</span>",
                    className: "btn btn-white btn-primary btn-xs",
                    fieldBoundary: "",
                    charset: false,
                    footer: true,
                    fieldSeparator: ";",
                    customize: eval(dataTable.exportCallback)
                });
            }
        }

        if (dataTable.pdf) {
            if (typeof dataTable.ajaxUrl != 'undefined') {

            } else {
                buttons.push({
                    extend: "pdf",
                    text: "<i class='fa fa-print bigger-110 grey'></i> <span class='hidden'>Print</span>",
                    className: "btn btn-white btn-primary btn-xs",
                    autoPrint: false,
                    footer: true,
                    orientation: 'landscape',
                    title: title,
                    customize: eval(dataTable.printCallback)
                });
            }
        }

        if (dataTable.csv || dataTable.pdf || Object.keys(dataTable.customButtons).length) {
            new $.fn.dataTable.Buttons(dataTable.table, {
                buttons: buttons
            });
            dataTable.table.buttons().container().appendTo($("#" + dataTable.idTable + "_wrapper").find('.dataTables_filter'));
        }

        if (typeof dataTable.ajaxUrl != 'undefined') {
            $("#" + dataTable.idTable + "_wrapper").find('.dataTables_filter').find('label').hide();
        }
    };

    this.beforeSuccessEditable = function (data, field, id, columnIndex, rowIndex, col) {
        return true;
    };

    this.afterSuccessEditable = function (data, field, id, columnIndex, rowIndex, col) {
    };

    this.beforeErrorEditable = function (data, field, id, columnIndex, rowIndex, col) {
        return true;
    };

    this.afterErrorEditable = function (data, field, id, columnIndex, rowIndex, col) {
    };

    this.extraParamsEditable = function (index, id, columnIndex, rowIndex, col) {
        return {};
    };

    this.redraw = function (nextFocus) {
        nextFocus = nextFocus || false;

        // prima di fare il redraw, mi salvo quale elemento in questo momento ha il focus così da ripristinarlo
        // in caso sia un elemento all'interno dell'attuale DataTable
        var $focus = $(document.activeElement);
        if (dataTable.table.cell($focus.closest('td')) && nextFocus) {
            dataTable.focusAfterDraw = dataTable.table.cell($focus.closest('td')).index();
        }
        dataTable.table.draw('page');
    };

    this.DTfnCreatedCell = function (td, data, rowData, rowIndex, columnIndex) {
        var id = rowData[0];

        var col = dataTable.columns[columnIndex];
        if (typeof col.editable != 'undefined') {
            var index = col.columnIndex;
            var url = col.editable.url || dataTable.ajaxEditUrl + ((typeof dataTable.ajaxUrlParameters != "undefined") ? "&" + dataTable.ajaxUrlParameters : "");
            var spinner = true;
            if (typeof col.editable.spinner != "undefined" && col.editable.spinner == false)
                spinner = false;

            switch (col.editable.type) {
                case 'select':
                    var autocomplete = "";
                    if (col.editable.autocomplete_url) {
                        autocomplete = col.editable.autocomplete_url;
                    } else {
                        autocomplete = $(dataTable.table.column(columnIndex).header()).attr("data-dt-field-url") || dataTable.ajaxUrl;
                        autocomplete += "&op=autocomplete&dt_editing=1&dt_col_index=" + index + (dataTable.ajaxUrlParameters ? "&" + dataTable.ajaxUrlParameters : '');
                    }

                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'select',
                        bind: 'select2:select',
                        id: id,
                        url: url,
                        nullable: ((typeof col.editable.nullable != 'undefined') ? col.editable.nullable : true),
                        nullValue: ((typeof col.editable.null_value != 'undefined') ? col.editable.null_value : null),
                        autocomplete: autocomplete,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(index, data, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'green');
                                    $element.attr('data-old-value', $element.val());
                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'red');
                                    $element.val($element.attr('data-old-value')).trigger('change');
                                }
                            }
                            dataTable.afterSuccessEditable(index, data, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(index, data, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'red');
                                app.error("", "Errore!");
                                $element.val($element.attr('data-old-value')).trigger('change');
                            }
                            dataTable.afterErrorEditable(index, data, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                case 'bool':
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'bool',
                        bind: 'click',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                if (data.response) {
                                    var value = $element.attr("data-value");
                                    if (value === "true") value = false;
                                    if (value === "false") value = true;
                                    $element.attr("data-value", value);
                                    if (value) {
                                        $element.html('<i class="fa fa-check green"></i>');
                                    } else {
                                        $element.html('<i class="fa fa-times red"></i>');
                                    }

                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                app.error("", "Errore!");
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                case "datetime":
                case "date":
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: col.editable.type,
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw) {
                                        dataTable.redraw(false);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');
                                app.error("", "Errore!");
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                case 'textarea':
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'textarea',
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');
                                app.error("", "Errore!");
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                    break;
                default:
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'text',
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');
                                app.error("", "Errore!");
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
            }
        }
    };

    this.make = function () {
        dataTable.makeColumns();
        dataTable.makeFilters();
        dataTable.makeSorting();
        dataTable.makeCustomButtons();
        dataTable.makeDT();
    };
};*/

/**
 * This plug-in for DataTables represents the ultimate option in extensibility
 * for sorting date / time strings correctly. It uses
 * [Moment.js](http://momentjs.com) to create automatic type detection and
 * sorting plug-ins for DataTables based on a given format. This way, DataTables
 * will automatically detect your temporal information and sort it correctly.
 *
 * For usage instructions, please see the DataTables blog
 * post that [introduces it](//datatables.net/blog/2014-12-18).
 *
 * @name Ultimate Date / Time sorting
 * @summary Sort date and time in any format using Moment.js
 * @author [Allan Jardine](//datatables.net)
 * @depends DataTables 1.10+, Moment.js 1.7+
 *
 * @example
 *    $.fn.dataTable.moment( 'HH:mm MMM D, YY' );
 *    $.fn.dataTable.moment( 'dddd, MMMM Do, YYYY' );
 *
 *    $('#example').DataTable();
 */
(function (factory) {
    if (typeof define === "function" && define.amd) {
        define(["jquery", "moment", "datatables"], factory);
    } else {
        factory(jQuery, moment);
    }
}(function ($, moment) {

    $.fn.dataTable.moment = function (format, locale) {
        var types = $.fn.dataTable.ext.type;

        // Add type detection
        types.detect.unshift(function (d) {
            // Strip HTML tags if possible
            if (d && d.replace) {
                d = d.replace(/<.*?>/g, '');
            }

            // Null and empty values are acceptable
            if (d === '' || d === null) {
                return 'moment-' + format;
            }

            return moment(d, format, locale, true).isValid() ?
            'moment-' + format :
                null;
        });

        // Add sorting method - use an integer for the sorting
        types.order['moment-' + format + '-pre'] = function (d) {
            if (d && d.replace) {
                d = d.replace(/<.*?>/g, '');
            }
            return d === '' || d === null ?
                -Infinity :
                parseInt(moment(d, format, locale, true).format('x'), 10);
        };
    };

}));

var debug = null;
var dataTable = function (options) {

    if (!options) {
        console.error("Specify options!");
        return;
    }

    var dataTable = this;

    // elenco delle righe selezionate ( formatter: dt_multiselect )
    this.selected_ids = [];

    /**
     * Oggetto contenente le traduzioni
     */
    this.T = {
        askDelete: undefined
    };

    this.idTable = "";
    this.subTableTemplate = "subTableTemplate";
    this.subBodyTemplate = "subBodyTemplate";
    this.table = undefined;

    this.fixedHeader = options.fixedHeader || false;
    this.csv = options.csv || false;
    this.pdf = options.pdf || false;

    this.select = options.select;
    if (typeof this.select == 'undefined')
        this.select = true;

    this.idTable = options.idTable || "";
    this.columns = options.columns || undefined;
    this.order = options.order || undefined;
    this.searchCols = options.searchCols || undefined;
    this.filterColumns = options.filterColumns || undefined;
    this.lenghtMenu = options.lenghtMenu || [[10, 50, 100, -1], ["10", "50", "100", "---"]];
    this.pagination = (typeof options.pagination != 'undefined') ? options.pagination : true;
    if (this.pagination === "true")
        this.pagination = true;
    if (this.pagination === "false")
        this.pagination = false;

    // indica se c'è una subgrid / edit o add inline aperto come row
    this.inlineOpen = false;

    this.$table = $("#" + dataTable.idTable);

    // se qualche colonna è specificata come editable permette che questa lo diventi altrimenti blocca l'edit inline
    // imposto a false solo se esplicitamete settiamo data-dt-editable a false
    this.editable = !(this.$table.attr('data-dt-editable') == 'false');

    // abilita la visualizzazione dell'utltima riga della dataTable contenente i totali
    this.totals = !(typeof this.$table.attr('data-dt-totals') == 'undefined' || this.$table.attr('data-dt-totals') == 'false');

    // se visualizzare o meno il block ui durante la fase di processing
    this.blockUI = (typeof options.blockUI != 'undefined' && options.blockUI) ? true : false;
    this.$table.on('processing.dt', function (a, b, processing) {
        if (dataTable.blockUI)
            app.blockUI(processing);
    });

    // titolo stampato
    this.title = this.$table.attr("data-dt-title");
    this.displayStart = this.$table.attr("data-dt-start");
    this.pageLength = this.$table.attr("data-dt-length");
    if (!this.title) this.title = "";

    // nome della configurazione
    this.configuration = this.$table.attr("data-dt-configuration");

    // nome della variabile in sessione per i parametri custom della tabella
    this.dt_session = this.$table.attr("data-dt-session") || undefined;

    // url per server processing e edit|delete
    this.baseUrl = this.$table.attr("data-dt-url");
    this.ajaxUrl = this.baseUrl + "/dt?dt=" + this.configuration + ((typeof this.dt_session != 'undefined') ? "&dt_session=" + this.dt_session : "");
    this.ajaxEditUrl = this.baseUrl + "/dt?op=inline&dt=" + this.configuration + ((typeof this.dt_session != 'undefined') ? "&dt_session=" + this.dt_session : "");

    this.ajaxUrlParameters = this.$table.attr("data-dt-url-parameters");

    // array di oggetti per i custom buttons. Di default viene gestito in automatico solo add
    this.customButtons = [];

    this.inlineButtons = false;

    this.scrollY = options.scrollY || undefined;
    this.scrollX = options.scrollY || undefined;

    // funzione per customizzare la stampa pdf
    this.printCallback = function (doc) {
        var colCount = [];
        dataTable.$table.find('tbody tr:first-child td').each(function () {
            if ($(this).attr('colspan')) {
                for (var i = 1; i <= $(this).attr('colspan'); i++) {
                    colCount.push('*');
                }
            } else {
                colCount.push('*');
            }
        });
        if (doc.content[1].table) {
            doc.content[1].table.widths = colCount;
        }
    };
    // override funzione stampa
    if (window[this.$table.attr("data-dt-print")] == 'function') this.printCallback = this.$table.attr("data-dt-print");

    // funzione per customizzare l'export
    this.exportCallback = this.$table.attr("data-dt-export");

    // serve per mettere il focus su un elemento una volta completato il redraw
    this.focusAfterDraw = undefined;

    this.nInlineButton = 0;

    // funzione che crea l'array di colonne per la dataTable
    this.makeColumns = function () {
        var doSort = (typeof dataTable.columns == "undefined" || typeof dataTable.columns == "undefined");
        var doFilter = (typeof dataTable.filterColumns == "undefined" || typeof dataTable.filterColumns == "undefined");
        if (doSort) dataTable.columns = [];
        if (doSort) dataTable.order = [];
        if (doFilter) dataTable.searchCols = [];
        if (doFilter) dataTable.filterColumns = [];
        var sort = undefined;
        var filter = undefined;
        var columnIndex = undefined;

        dataTable.$table.find("thead").find("tr").find("th").each(function (index) {
            sort = $(this).attr("data-dt-sort");
            if (typeof sort != "undefined" && $.trim(sort) != "" && sort !== 1) {
                sort = $.parseJSON(sort);
            }
            filter = $(this).attr("data-dt-filter");
            if (typeof filter != "undefined")
                filter = $.parseJSON(filter);
            columnIndex = $(this).attr("data-dt-column-index");

            if (typeof $(this).attr("data-dt-inline-button") != 'undefined') {
                var colBtnVisible = false;
                var subgridParam = $(this).attr("data-dt-subgrid") || undefined;
                var buttons = "";
                if (typeof subgridParam != 'undefined') {
                    var subPar = JSON.parse(subgridParam);
                    colBtnVisible = true;
                    dataTable.inlineButtons = true;
                    $.each(subPar, function (i, obj) {
                        buttons = buttons + '<button data-interaction="subgrid" data-method="' + ((typeof obj.method != "undefined") ? obj.method : "post") + '" data-url="' + obj.url + '" class="btn btn-link btn-warning"><i data-class-close="' + (obj.icon || 'fa fa-minus') + '" data-class-open="' + (obj.icon || 'fa fa-plus') + '" class="' + (obj.icon || 'fa fa-plus') + '"></i></button>';
                        dataTable.nInlineButton++;
                    });
                }
                if ($(this).attr("data-dt-inline-button") == "1") {
                    // colonna bottoni inline
                    colBtnVisible = true;
                    dataTable.inlineButtons = true;
                    var buttonsConfig = $(this).attr("data-dt-button") || 'edit|delete';
                    var editUrl = $(this).attr("data-dt-edit-url") || dataTable.baseUrl;
                    var deleteUrl = $(this).attr("data-dt-delete-url") || dataTable.baseUrl;

                    $.each(buttonsConfig.split("|"), function (i, obj) {
                        var method = "get";
                        if (obj.indexOf("[") > -1) {
                            method = obj.substr(obj.indexOf("[") + 1, obj.indexOf("]") - obj.indexOf("[") - 1);
                            obj = obj.substr(0, obj.indexOf("["));
                        }
                        dataTable.nInlineButton++;
                        switch (obj) {
                            case "check":
                                buttons = buttons + "<input data-interaction='check' type='checkbox'>";
                                break;
                            case "edit":        // TODO come gestiamo le lingue? "Edit"
                                buttons = buttons + '<a title="Edit" data-dt-inline-button="1" data-interaction="edit" href="' + editUrl + '" class="btn btn-link btn-warning"><i class="fa fa-pencil"></i></a>';
                                break;
                            case "edit_inline":
                                buttons = buttons + '<button data-method="' + method + '" title="Edit" data-dt-inline-button="1" data-interaction="edit_inline" data-url="' + editUrl + '" class="btn btn-link btn-warning"><i class="fa fa-pencil"></i></button>';
                                break;
                            case "delete":      // TODO come gestiamo le lingue? "Delete"
                                buttons = buttons + '<button title="Delete" type="button" data-dt-inline-button="1" data-reload="true" data-interaction="delete" data-url="' + deleteUrl + '" class="btn btn-link btn-danger"><i class="fa fa-trash red"></i></button>';
                                break;
                        }
                    });
                }

                dataTable.columns.push({
                    data: null,
                    defaultContent: buttons,
                    className: 'btn-inline-edit',
                    orderable: false,
                    visible: colBtnVisible,
                    createdCell: function (td, cellData) {
                        $(td).find('[data-interaction=subgrid]').attr("data-url", $(td).find('[data-interaction=subgrid]').attr("data-url") + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                        $(td).find('[data-interaction=delete]').attr("data-url", deleteUrl + "/" + cellData[0] + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                        $(td).find('[data-interaction=edit]').attr("href", editUrl + "/" + cellData[0] + "/edit" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                        $(td).find('[data-interaction=check]').attr("data-id", cellData[0]);
                        $(td).find('[data-interaction=edit_inline]').attr("data-url", editUrl + "/" + cellData[0] + "/edit" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                    }
                });
                dataTable.searchCols.push(null);
            } else {
                // colonne dati
                var formatterFunction = $(this).attr("data-dt-formatterjs") || undefined;
                var align = $(this).attr("data-dt-align") || undefined;
                var editable = $(this).attr("data-editable") || undefined;

                if (doSort) {
                    var objColumn = {};

                    if (typeof sort != "undefined" && $.trim(sort) != "" && sort != "false" && sort != "0") {
                        objColumn = {"bSortable": true};
                        if (typeof sort.order != "undefined" && sort.order != null) {
                            dataTable.order.push([index, sort.order.toLowerCase(), (sort.priority != 'undefined' ? sort.priority : 1)]);
                        }
                    } else {
                        objColumn = {"bSortable": false};
                    }

                    if (typeof align != "undefined")
                        objColumn.sClass = "text-" + align;

                    objColumn.columnIndex = columnIndex;

                    if (formatterFunction) {
                        var formatterFunctionCallback = null;

                        try {
                            // il formatter è parte del set base dei formatter
                            if (typeof(window['formatter']) == 'object')
                                if (typeof(window['formatter'][formatterFunction]) == 'function')
                                    formatterFunctionCallback = window['formatter'][formatterFunction];

                            // il formatter è una funzione custom nella forma 'nomeFunzione' o 'oggetto.nomeFunzione'
                            if (formatterFunctionCallback == null) {
                                if (typeof(window[formatterFunction]) == 'function') {
                                    formatterFunctionCallback = window[formatterFunction];
                                } else if (formatterFunction.indexOf('.') > 0) {
                                    var fnObj = formatterFunction.split('.')[0];
                                    var fnFunction = formatterFunction.split('.')[1];

                                    if (typeof(window[fnObj][fnFunction]) == 'function') {
                                        formatterFunctionCallback = window[fnObj][fnFunction];
                                    }
                                }
                            }

                            if (typeof formatterFunctionCallback == 'function') {
                                objColumn.createdCell = function (td, cell_data, row_data, row_index, col_index) {
                                    // if (!dataTable.editable)
                                    $(td).html(formatterFunctionCallback(dataTable, td, cell_data, row_data, row_index, col_index));
                                    dataTable.DTfnCreatedCell(td, cell_data, row_data, row_index, col_index);
                                };
                            } else {
                                console.warn("Callback '" + formatterFunction + "' not found (column index: " + index + ")");
                            }
                        } catch (err) {
                            console.warn("Callback '" + formatterFunction + "' not found (column index: " + index + ")");
                        }
                    }

                    if (editable) {
                        objColumn.editable = $.parseJSON(editable);
                    }

                    dataTable.columns.push(objColumn);
                }

                if (doFilter) {
                    if (typeof filter != "undefined" && filter != "false" && filter != "0") {
                        if (typeof filter.init_value != "undefined" && filter.init_value != null) {
                            dataTable.searchCols.push({
                                search: "" + filter.init_value.id
                            });
                        } else {
                            dataTable.searchCols.push(null);
                        }

                        dataTable.filterColumns[index] = {
                            index: index,
                            columnIndex: columnIndex,
                            filter: filter
                        };
                    }
                }
            }
        });

        dataTable.order.sort(function (a, b) {
            return a[2] - b[2];
        });
    };

    this.makeFilters = function () {
        var filter = dataTable.$table.find('thead').attr("data-dt-enable-filter");
        if (filter !== undefined && filter != undefined && typeof filter != "undefined" && filter != "false" && filter != "0") {
            /**
             * Ho abilitato i filtri.
             * Creo una nuova tr dove ci saranno i filtri
             */
            var n = dataTable.$table.find("thead").find("tr").find("th").length;
            var $tr = $('<tr data-dt-filter="1"></tr>');
            for (var i = 0; i < n; i++) {
                var $th = $('<th data-filter="' + i + '">' + +'</th>');
                $tr.append($th);
            }
            var content = dataTable.$table.find("thead").html();
            dataTable.$table.find("thead").html($tr);
            dataTable.$table.find("thead").append(content);
        }
    };

    this.makeSorting = function () {
        // date
        $.fn.dataTable.moment('DD/MM/YYYY');

        // numeric
        $.fn.dataTable.ext.order['numeric'] = function (settings, col) {
            return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                var value = $(td).html().trim();
                value = value.split(",").join("");
                value = value.split("--").join("0");
                return parseFloat(value);
            });
        };

        // numeric comma
        $.fn.dataTable.ext.order['numeric-comma'] = function (settings, col) {
            return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
                var value = $(td).html().trim();
                value = value.split(".").join("");
                value = value.split(",").join(".");
                value = value.split("--").join("0");
                return parseFloat(value);
            });
        };
    };

    this.DTfnBeforeDrawCallback = function (settings) {
    };
    this.DTfnDrawCallback = function (settings) {
        dataTable.DTfnBeforeDrawCallback(settings);
        dataTable.bindEvents();
        if (dataTable.focusAfterDraw !== undefined) {
            $(dataTable.table.cell(dataTable.focusAfterDraw).node()).find('.data-dt-focus').focus();
            dataTable.focusAfterDraw = undefined;
        }

        dataTable.DTfnAfterDrawCallback(settings);
    };
    this.DTfnAfterDrawCallback = function (settings) {
    };

    /**
     * Funzione da overridare
     */
    this.DTbeforeInitComplete = function () {

    };
    /**
     * Funzione da overridare
     */
    this.DTafterInitComplete = function () {

    };

    this.DTinitComplete = function (settings, json) {
        dataTable.DTbeforeInitComplete();

        this.api().columns().every(function (i) {
            var filterColumn = dataTable.filterColumns[i];
            if (filterColumn) {
                var column = this;
                var $search = undefined;
                var $from = undefined;
                var $to = undefined;
                var $appendTo = $(this.header()).parent().parent().find("tr:first").find("[data-filter='" + i + "']");
                var jsonVal = undefined;

                var filter = filterColumn.filter;
                var type = filter.type;
                if (typeof type == 'undefined' || type == null || type.trim() == '')
                    type = 'select';

                switch (type) {
                    case "time":
                    case "date":
                    case "datetime":
                    case "timesingle":
                    case "datesingle":
                    case "datetimesingle":
                        var c = 'datepicker';
                        var place = 'Da';
                        if (filter.type == 'datetime' || filter.type == 'datetimesingle') c = 'datetimepicker';
                        if (filter.type == 'datetimesingle' || filter.type == 'datesingle') place = "";
                        $from = $('<input type="text" class="' + c + ' dtfrom" placeholder="' + place + '">');
                        // var $calendar = $('<span class="input-group-addon"><i class="fa fa-calendar"></i></span>');
                        // var $fromWrapper = $('<div class="input-group dtDateRangeWrapper"></div>');
                        // $fromWrapper.append($calendar);
                        // $fromWrapper.append($from);
                        $from.appendTo($appendTo.empty());
                        var timepicker = false;
                        var datepicker = true;
                        var format = 'd/m/Y';
                        if (filter.type == 'datetime') format = 'd/m/Y H:i:s';
                        if (filter.type == 'time') {
                            format = 'H:i';
                            timepicker = true;
                            datepicker = false;
                        }
                        $from.datetimepicker({
                            dayOfWeekStart: 1,
                            // mask: true,
                            format: format,
                            closeOnDateSelect: true,
                            datepicker: datepicker,
                            timepicker: timepicker,
                            scrollInput: false
                        });
                        $from.on('change', function () {
                            var from = $(this).val();
                            if (filter.type == 'datesingle' || filter.type == 'datetimesingle') {
                                column.search(from ? from : '', true, false).draw();
                            } else {
                                var to = $(this).closest('th').find('.dtto').val();
                                from = from ? from : "";
                                to = to ? to : "";
                                if ($.trim(from) == "" && $.trim(to) == "")
                                    column.search("", true, false).draw();
                                else
                                    column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                            }
                        });

                        if (
                            typeof filter.init_value != "undefined" &&
                            typeof filter.init_value.id != "undefined" &&
                            filter.init_value.id != null &&
                            $.trim(filter.init_value.id) != ""
                        ) {
                            jsonVal = JSON.parse(filter.init_value.id);
                            $from.val(jsonVal.from);
                        }

                        if (filter.type == "date" || filter.type == "datetime"|| filter.type == "time") {
                            place = 'A';
                            if (filter.type == 'datetimesingle' || filter.type == 'datesingle') place = "";
                            $to = $('<input type="text" class="' + c + ' dtto" placeholder="' + place + '">');
                            // $calendar = $('<span class="input-group-addon"><i class="fa fa-calendar"></i></span>');
                            // var $toWrapper = $('<div class="input-group dtDateRangeWrapper"></div>');
                            // $toWrapper.append($calendar);
                            // $toWrapper.append($to);
                            $to.appendTo($appendTo);
                            $to.datetimepicker({
                                dayOfWeekStart: 1,
                                // mask: true,
                                format: format,
                                closeOnDateSelect: true,
                                datepicker: datepicker,
                                timepicker: timepicker,
                                scrollInput: false
                            });
                            $to.on('change', function () {
                                var to = $(this).val();
                                var from = $(this).closest('th').find('.dtfrom').val();
                                from = from ? from : "";
                                to = to ? to : "";
                                if ($.trim(from) == "" && $.trim(to) == "")
                                    column.search("", true, false).draw();
                                else
                                    column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                            });

                            if (
                                typeof filter.init_value != "undefined" &&
                                typeof filter.init_value.id != "undefined" &&
                                filter.init_value.id != null &&
                                $.trim(filter.init_value.id) != ""
                            ) {
                                jsonVal = JSON.parse(filter.init_value.id);
                                $to.val(jsonVal.to);
                            }
                        }
                        break;
                    case "number":
                        $from = $('<input placeholder="Da" type="text" class="dtfrom" style="width: 100%;">'); // TODO gestione lingue
                        $from.appendTo($appendTo.empty());
                        $from.on('click', function (e) {
                            e.stopPropagation();
                        });
                        $from.on('change', function () {
                            var from = $(this).val();
                            var to = $(this).closest('th').find('.dtto').val();
                            from = from ? from : "";
                            to = to ? to : "";
                            if ($.trim(from) == "" && $.trim(to) == "")
                                column.search("", true, false).draw();
                            else
                                column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                        });

                        $to = $('<input placeholder="A" type="text" class="dtto" style="width: 100%;">'); // TODO gestione lingue
                        $to.appendTo($appendTo);
                        $to.on('change', function () {
                            var to = $(this).val();
                            var from = $(this).closest('th').find('.dtfrom').val();
                            from = from ? from : "";
                            to = to ? to : "";
                            if ($.trim(from) == "" && $.trim(to) == "")
                                column.search("", true, false).draw();
                            else
                                column.search(JSON.stringify({from: from, to: to}), true, false).draw();
                        });
                        break;
                    case 'select':
                        $search = $('<select class="select2" style="width: 100%;" data-placeholder=" "><option value=""></option></select>')
                            .appendTo($appendTo.empty())
                            .on('change', function () {
                                var val = $(this).val();
                                column.search(val ? val : '', true, false).draw();
                            });

                        // gestione valore iniziale
                        column.data().unique().sort().each(function (d) {
                            if (filter.init_value) {
                                $search.append('<option selected value="' + filter.init_value.id.replace('"', '&quot;') + '">' + filter.init_value.text + '</option>');
                            }
                        });

                        var columnIndex = filterColumn.columnIndex;

                        var url = filter.url || dataTable.ajaxUrl;

                        if (typeof columnIndex != 'undefined') {
                            // creo l'url che verrà contattata dalla select2 per recuperare i dati
                            if (url.indexOf("?") > -1) {
                                url = url + "&op=autocomplete&dt_col_index=" + columnIndex + (dataTable.ajaxUrlParameters ? "&" + dataTable.ajaxUrlParameters : '');
                            } else {
                                url = url + "?op=autocomplete&dt_col_index=" + columnIndex + (dataTable.ajaxUrlParameters ? "&" + dataTable.ajaxUrlParameters : '');
                            }

                            // template di visualizzazione dei risultati della select2
                            function templateResult(data) {
                                return data.text;
                            }

                            // template di visualizzazione del risultato selezionato della select2
                            function templateSelection(data) {
                                if (typeof data.label == "undefined")
                                    data.label = data.text;
                                return data.label;
                            }

                            var minimumInputLength = 0;
                            if (typeof filter.preload != "undefined" && filter.preload == false)
                                minimumInputLength = 1;

                            $search.select2({
                                ajax: {
                                    url: url,
                                    dataType: 'json',
                                    delay: 100,
                                    type: 'POST',
                                    data: function (params) {
                                        return {
                                            search: params.term, // search term
                                            page: params.page
                                        };
                                    },
                                    processResults: function (data, params) {
                                        // parse the results into the format expected by Select2
                                        // since we are using custom formatting functions we do not need to
                                        // alter the remote JSON data, except to indicate that infinite
                                        // scrolling can be used
                                        params.page = params.page || 1;

                                        return {
                                            results: data.items
                                        };
                                    },
                                    cache: true
                                },
                                escapeMarkup: function (markup) {
                                    return markup;
                                },
                                minimumInputLength: minimumInputLength,
                                allowClear: true,
                                cache: true,
                                templateResult: templateResult,
                                templateSelection: templateSelection
                            }).on("select2:unselecting", function (e) {
                                $(this).data('state', 'unselected');
                            });
                        }
                        break;
                }
            }
        });

        dataTable.DTafterInitComplete();
    };

    this.DTfnCreatedRow = function (nRow, aData) {
        $(nRow).attr('data-id', aData[0]);
        $(nRow).children("td").css("overflow", "hidden");
        $(nRow).children("td").css("white-space", "nowrap");
        $(nRow).children("td").css("text-overflow", "ellipsis");

        // per ogni cella forzo la max-width della th relativa
        var index = 0;
        $(nRow).find("td").each(function () {
            if (typeof dataTable.columnDefs[index] != "undefined" && typeof dataTable.columnDefs[index].width != "undefined")
                $(this).css("max-width", dataTable.columnDefs[index].width);
            index++;
        });

        dataTable.afterDTfnCreatedRow(nRow, aData);
    };

    this.afterDTfnCreatedRow = function (nRow, aData) {};

    this.afterEditInline = function (dt, $row, data) {
        var c = new crud({
            form: $row.find("form").attr("id")
        });

        c.successSave = function ($form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                app.success("", "Salvato!");
                dataTable.table.draw("page");
                dataTable.inlineOpen = false;
            } else {
                app.warning("", data.message);
            }
            if (typeof callback != 'undefined') {
                var fnz = app.eval(callback);
                fnz(data);
            }
            if (!hideLoader)
                app.block(0);
        };
        c.successDelete = function ($form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                dataTable.table.draw("page");
                dataTable.inlineOpen = false;
            } else {
                app.warning("", data.message);
            }
            if (typeof callback != 'undefined') {
                window[callback](data);
            }
            if (!hideLoader)
                app.block(0);
        };
        $row.find('[data-interaction=cancel]').unbind('click').bind('click', function (e) {
            e.preventDefault();

            $row.hide();

            dataTable.inlineOpen = false;
        });
    };

    this.bindEvents = function () {
        // si assicura che le colonne con i filtri siano allineate con le colonne visualizzate
        // questo perchè con responsive: true alcune colonne vengono nascoste così vado a nascondere anche i filtri correlati
        var onResize = function () {
            if (dataTable.table) {
                dataTable.table.columns().every(function (i) {
                    var $th = $(this.header()).parent().parent().find("tr:first").find("[data-filter='" + i + "']");
                    if (!$(this.header()).is(":visible")) {
                        $th.hide();
                    } else {
                        $th.show();
                    }
                });
            }
        };
        var resize = undefined;
        $(window).resize(function () {
            clearTimeout(resize);
            resize = setTimeout(function () {
                onResize();
            }, 250);
        });

        setTimeout(function () {
            onResize();
        }, 250);

        dataTable.$table.find('thead').find('select').on('change', function () {
            dataTable.bindEvents();
        });

        dataTable.$table.find('select[name="ordersTable_length"]').on('change', function () {
            dataTable.bindEvents();
        });

        dataTable.$table.find(".paginate_button").click(function () {
            dataTable.bindEvents();
        });

        dataTable.$table.find('[data-interaction="edit_inline"]').unbind("click").bind("click", function () {
            var url = $(this).attr("data-url");
            var method = $(this).attr("data-method");
            var row = dataTable.table.row($(this).closest('tr'));

            if (row.child.isShown()) {
                row.child.hide();
                dataTable.inlineOpen = false;
            } else {
                if (dataTable.inlineOpen == false) {
                    $[method](url)
                        .done(function (data) {
                            var child = row.child(data);
                            child.show();
                            app.runBind();
                            dataTable.inlineOpen = true;
                            dataTable.afterEditInline(dataTable, $(row.child()[0]), data);
                        })
                        .fail(function () {
                            app.error("", "Errore server!");
                        });
                }
            }
        });

        dataTable.$table.find('[data-interaction="subgrid"]').unbind("click").bind("click", function () {

            var objI = $(this).find('i');
            if (objI.hasClass(objI.attr('data-class-open'))) {
                objI.removeClass(objI.attr('data-class-open'));
                objI.addClass(objI.attr('data-class-close'));
            } else {
                objI.removeClass(objI.attr('data-class-close'));
                objI.addClass(objI.attr('data-class-open'));
            }

            var $tr = $(this).closest('tr');
            var id = $(this).attr("data-id") || $(this).closest('tr').attr('data-id');
            var url = $(this).attr("data-url");
            var dt = $(this).closest('table').attr('data-dt-configuration');
            var method = $(this).attr("data-method");
            var callbackName = $(this).attr("data-callback");
            var row = dataTable.table.row($tr);

            // verifico se la callback esiste
            var callback = false;
            if (typeof callbackName != 'undefined') {
                if (jQuery.isFunction(callbackName))
                    callback = true;
                if (jQuery.isFunction(eval(callbackName)))
                    callback = true;
            }

            if (row.child.isShown()) {
                row.child.hide();
                $tr.removeClass('shown');
            } else {
                app.block(1);
                $[method](url, {id_subgrid: id, dt: 'default_' + id}) // FIXME dirt
                    .success(function (data) {
                        if (data) {
                            if (callback) {
                                eval(callbackName)(row, data);
                            } else {
                                row.child(data).show();
                                $tr.addClass('shown');
                            }
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                    });
            }
        });

        if (dataTable.selected_ids.length) {
            // se sono elementi che devono essere selezionati paginando, li seleziono
            $.each(dataTable.selected_ids, function (index, obj) {
                dataTable.$table.find('[data-interaction="check"][data-id="' + obj + '"]').prop("checked", true);
            });
        }
        dataTable.$table.find('[data-interaction="check"]').unbind("change").bind("change", function () {
            var id_row = $(this).attr("data-id");
            if ($(this).is(":checked")) {
                dataTable.selected_ids.push(id_row);
            } else {
                var pos = dataTable.selected_ids.indexOf(id_row)
                if (pos != -1) {
                    dataTable.selected_ids.splice(pos, 1);
                }
            }
        });

        dataTable.$table.find('[data-dt-inline-button="1"][data-interaction=delete]').unbind('click').bind('click', function () {
            var url = $(this).attr('data-url');
            var conferma = dataTable.T.askDelete || 'Eliminare la riga selezionata?';

            var objButton = $(this);

            // formattazione stringa richiesta parsando le {}
            $.each(dataTable.columns, function (index, obj) {
                if (typeof obj.field != 'undefined') {
                    if (conferma.indexOf("{" + obj.field + "}") != -1) {

                        var idRiga = objButton.parents("tr[data-id]").attr("data-id");
                        var valInCell = dataTable.table.cell("[data-id=" + idRiga + "] td:eq(" + index + ")").data();

                        // recupero il campo nella colonna alla riga attuale
                        conferma = conferma.replace("{" + obj.field + "}", valInCell);
                    }
                }
            });

            if (confirm(conferma)) {
                app.block(1);
                $.delete(url)
                    .success(function (data) {
                        if (data.response) {
                            dataTable.table.draw('page');
                        } else {
                            app.warning("", data.message);
                        }
                        app.block(0);
                    })
                    .error(function () {
                        app.block(0);
                        app.error('', 'Delete error!');
                    });
            }
        });

        this.afterBindEvents();
    };

    this.getSelectedRows = function () {
        return dataTable.selected_ids;
    };

    this.afterBindEvents = function () {
    };

    this.makeCustomButtons = function () {
        var buttons = dataTable.$table.attr("data-dt-custom-button");
        if (buttons && buttons.trim() != "") {
            $.each(buttons.split("|"), function (index, obj) {
                var method = "get";
                if (obj.indexOf("[") > -1) {
                    method = obj.substr(obj.indexOf("[") + 1, obj.indexOf("]") - obj.indexOf("[") - 1);
                    obj = obj.substr(0, obj.indexOf("["));
                }
                switch (obj) {
                    case 'add':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-plus"></i> Aggiungi',
                                className: "btn btn-white btn-success btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    app.href(dataTable.baseUrl + "/create" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : ''));
                                }
                            };
                        }
                        break;
                    case 'add_inline':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-plus"></i> Aggiungi',
                                className: "btn btn-white btn-success btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    if (dataTable.editable) {
                                        var url = dataTable.baseUrl + "/create" + (dataTable.ajaxUrlParameters ? "?" + dataTable.ajaxUrlParameters : '');

                                        // inserisco al posto del messaggio che dice che non ci sono dati
                                        var $row = $(dataTable.table.table().body());

                                        if (dataTable.inlineOpen == false) {
                                            $[method](url, {dt_inline: 1})
                                                .done(function (data) {
                                                    var $tr = $('<tr></tr>');
                                                    var $td = $('<td colspan="' + dataTable.columns.length + '"></td>');
                                                    $td.html(data);
                                                    $tr.html($td);
                                                    $row.prepend($tr);
                                                    $row.find('form').find('.row').css('background-color', 'transparent');
                                                    $row.find('.form-group').css('margin-bottom', '5px');
                                                    $row.find('input[type=text]').css('margin', 'auto');
                                                    $row.find('.form-control').css('width', '100%');
                                                    app.runBind();
                                                    dataTable.inlineOpen = true;
                                                    dataTable.afterEditInline(dataTable, $tr, data);
                                                })
                                                .fail(function () {
                                                    app.error("", "Errore server!");
                                                });
                                        }
                                    } else {
                                        app.warning("", "Devi prima abilitare le modifiche!");
                                    }
                                }
                            };
                        }
                        break;
                    case 'add_default':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-plus"></i> Aggiungi',
                                className: "btn btn-white btn-success btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    app.block(1);
                                    $.post(dataTable.baseUrl + (typeof dataTable.ajaxUrlParameters != 'undefined' ? "?" + dataTable.ajaxUrlParameters : ""))
                                        .done(function (data) {
                                            if (data.response) {
                                                if (typeof dataTable.customButtons['lock'] != 'undefined') {
                                                    if (!dataTable.editable) {
                                                        // trigger del tasto lock
                                                        dataTable.customButtons['lock'].action(e, dt, $(node).parent().find(".btn-lock"), config)
                                                    }
                                                }
                                                dataTable.table.draw('page');
                                            } else {
                                                app.warning("", data.message);
                                            }
                                            app.block(0);
                                        })
                                        .fail(function () {
                                            app.block(0);
                                            app.error("", "Errore AJAX!");
                                        });
                                }
                            };
                        }
                        break;
                    case 'clear':
                    case 'forget':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-eraser"></i> Pulisci',
                                className: "btn btn-white btn-warning btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    app.block(1);
                                    $.post(dataTable.ajaxUrl + "&op=forget" + (dataTable.ajaxUrlParameters ? "&" + dataTable.ajaxUrlParameters : ""))
                                        .done(function (data) {
                                            if (data.response)
                                            // non eseguo solo il draw per evitare di risettare filtri e di esser sicuro di riscrivere la sessione
                                                app.reload();
                                            app.block(0);
                                        })
                                        .fail(function () {
                                            app.block(0);
                                            app.error("", "Errore AJAX!");
                                        });
                                }
                            };
                        }
                        break;
                    case 'refresh':
                    case 'redraw':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-refresh"></i> Aggiorna',
                                className: "btn btn-white btn-success btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    dataTable.table.draw('page');
                                }
                            };
                        }
                        break;
                    case 'clone':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-clone"></i> Duplica',
                                className: "btn btn-white btn-primary btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    if (dataTable.table.rows('.selected').data().length > 0) {
                                        if (dataTable.table.rows('.selected').data()[0].length > 0) {
                                            var id = dataTable.table.rows('.selected').data()[0][0];

                                            app.block(1);
                                            $.post(dataTable.baseUrl, {clone: id})
                                                .done(function (data) {
                                                    if (data.response) {
                                                        app.success("", "Elemento duplicato");
                                                        dataTable.table.draw('page');
                                                    } else {
                                                        app.warning("", data.message);
                                                    }
                                                    app.block(0);
                                                })
                                                .fail(function () {
                                                    app.block(0);
                                                    app.error("", "Errore AJAX!");
                                                });
                                        }
                                    } else {
                                        app.warning("", "Seleziona una riga!");
                                    }
                                }
                            };
                        }
                        break;
                    case 'csv':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: "<i class='fa fa-file-excel-o bigger-110'></i> CSV",
                                className: "btn btn-white btn-info btn-xs btn-margin-right",
                                action: function (e, dt, node, config) {
                                    var url = dataTable.ajaxUrl + "&op=export&type=csv&" + dataTable.ajaxUrlParameters;

                                    var params = dataTable.table.ajax.params();
                                    params.start = 0;
                                    params.length = -1;

                                    app.block(1);
                                    $.ajax({
                                            url: url,
                                            method: "POST",
                                            async: false,
                                            data: params
                                        })
                                        .success(function (data) {
                                            app.block(0);
                                            if (data.response) {
                                                app.locationHref(data.message, true);
                                            } else {
                                                app.warning("", data.message);
                                            }
                                        })
                                        .error(function () {
                                            app.block(0);
                                            app.error("", "Errore esportazione CSV!");
                                        });
                                }
                            }
                        }
                        break;
                    case 'lock':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: "<i class='fa fa-" + ((dataTable.editable) ? "unlock" : "lock") + " bigger-110'></i>",
                                className: "btn-lock btn btn-white btn-" + ((dataTable.editable) ? "danger" : "warning") + " btn-xs",
                                action: function (e, dt, node, config) {
                                    if (dataTable.editable) {
                                        // lock
                                        $(node)
                                            .removeClass('btn-danger')
                                            .addClass('btn-warning')
                                            .find('i')
                                            .removeClass('fa-unlock')
                                            .addClass('fa-lock');
                                        dataTable.editable = false;
                                    } else {
                                        // unlock
                                        $(node)
                                            .removeClass('btn-warning')
                                            .addClass('btn-danger')
                                            .find('i')
                                            .removeClass('fa-lock')
                                            .addClass('fa-unlock');
                                        dataTable.editable = true;
                                    }

                                    dataTable.table.draw('page');
                                }
                            }
                        }
                        break;
                    case 'check_all':
                        if (typeof dataTable.customButtons[obj] == 'undefined') {
                            dataTable.customButtons[obj] = {
                                text: '<i class="fa fa-check"></i> <span data-checked="0">Seleziona tutti</span>',
                                className: "btn btn-white btn-info btn-xs btn-margin-right",
                                action: function (e, table, node, config) {
                                    var $span = $(node).find('[data-checked]');
                                    var checked = parseInt($span.attr('data-checked'));
                                    if (checked) {
                                        dataTable.$table.find("[data-interaction=check]:checked").click();
                                        $span.attr('data-checked', '0');
                                        $span.html('Seleziona tutti');
                                    } else {
                                        dataTable.$table.find("[data-interaction=check]").not(':checked').click();
                                        $span.attr('data-checked', '1');
                                        $span.html('Deseleziona tutti');
                                    }
                                }
                            }
                        }
                        break;
                }
            });
        }

        this.afterMakeCustomButtons();
    };

    this.afterMakeCustomButtons = function () {};

    this.makeDT = function () {
        $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
            // TODO: sostituire magari con un messaggio generico migliore
            app.error("", message);
        };

        var language = typeof datatable_lang != 'undefined' ? datatable_lang : {
            "sEmptyTable": "Nessun dato presente nella tabella",
            "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
            "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
            "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "Visualizza _MENU_ elementi",
            "sLoadingRecords": "Caricamento...",
            "sProcessing": "Elaborazione...",
            "sSearch": "Cerca:",
            "sZeroRecords": "La ricerca non ha portato alcun risultato.",
            "oPaginate": {
                "sFirst": "Inizio",
                "sPrevious": "Precedente",
                "sNext": "Successivo",
                "sLast": "Fine"
            },
            "select": {
                "rows": {
                    "_": "You have selected %d rows",
                    "0": "Click a row to select it",
                    "1": "Only 1 row selected"
                }
            },
            "oAria": {
                "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
                "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
            }
        };

        // imposto la width delle colonne così come vengono create all'inizio così da avere la gestione della width lato controller
        dataTable.columnDefs = [];
        var index = 0;

        // width dei bottoni inline
        if (dataTable.$table.find("thead").find("tr").find("[data-dt-inline-button=1]")) {
            dataTable.$table.find("thead").find("tr").find("[data-dt-inline-button=1]").css({
                'min-width': dataTable.nInlineButton * 25 + "px",
                'width': dataTable.nInlineButton * 25 + "px"
            });
            index++;
        }

        // fisso le colonne in modo che usando i filtri queste non si ridimensionano
        dataTable.$table.find("thead").find("tr").find('[data-dt-filter]').each(function () {
            dataTable.columnDefs.push({
                width: $(this).width(),
                targets: index++,
                createdCell: dataTable.DTfnCreatedCell
            });
        });

        var options = {
            bAutoWidth: false,
            aoColumns: dataTable.columns,
            columnDefs: dataTable.columnDefs,
            displayStart: parseInt(dataTable.displayStart),
            pageLength: parseInt(dataTable.pageLength),
            searchCols: dataTable.searchCols,
            order: dataTable.order,
            aaSorting: [],
            aLengthMenu: dataTable.lenghtMenu,
            bLengthChange: true,
            bPaginate: dataTable.pagination,
            select: dataTable.select,
            responsive: {
                details: false
            },

            // language: prende la variabile globale datatable_lang oppure valori di default
            language: language,

            // gestione server side ajax
            processing: false,
            serverSide: (typeof dataTable.ajaxUrl != 'undefined'),
            ajax: {
                url: (typeof dataTable.ajaxUrl != 'undefined') ? dataTable.ajaxUrl + (this.ajaxUrlParameters ? "&" + this.ajaxUrlParameters : '') : false,
                type: 'POST'
            },

            footerCallback: dataTable.footerCallback,
            fnDrawCallback: dataTable.DTfnDrawCallback,
            initComplete: dataTable.DTinitComplete,
            fnCreatedRow: dataTable.DTfnCreatedRow
        };

        if (typeof dataTable.scrollY != 'undefined') {
            // TODO: quando è attivo lo scroll la DT non si ridimensiona più!
            $.extend(options, dataTable.scrollY);
        }

        // DATATABLE
        dataTable.table = dataTable.$table.DataTable(options);

        if (dataTable.fixedHeader) {
            new $.fn.dataTable.FixedHeader(dataTable.table);
        }

        var buttons = [];
        var custom = $.extend([], dataTable.customButtons);
        var buttonsLength = Object.keys(custom).length;

        var buttonsOrder = dataTable.$table.attr("data-dt-custom-button");
        $.each(buttonsOrder.split('|'), function (i, v) {
            if (typeof custom[v] != 'undefined') {
                buttons.push(custom[v]);
                delete custom[v];
            }
        });

        $.each(Object.keys(custom), function (index, obj) {
            buttons.push(custom[obj]);
        });

        if (dataTable.csv) {
            buttons.push({
                extend: "csvHtml5",
                text: "<i class='fa fa-database bigger-110 orange'></i> <span class='hidden'>Export to CSV</span>",
                className: "btn btn-white btn-primary btn-xs",
                fieldBoundary: "",
                charset: false,
                footer: true,
                fieldSeparator: ";",
                customize: eval(dataTable.exportCallback)
            });
        }

        if (dataTable.pdf) {
            if (typeof dataTable.ajaxUrl != 'undefined') {

            } else {
                buttons.push({
                    extend: "pdf",
                    text: "<i class='fa fa-print bigger-110 grey'></i> <span class='hidden'>Print</span>",
                    className: "btn btn-white btn-primary btn-xs",
                    autoPrint: false,
                    footer: true,
                    orientation: 'landscape',
                    title: title,
                    customize: eval(dataTable.printCallback)
                });
            }
        }

        if (dataTable.csv || dataTable.pdf || buttonsLength) {
            new $.fn.dataTable.Buttons(dataTable.table, {
                buttons: buttons
            });
            dataTable.table.buttons().container().appendTo($("#" + dataTable.idTable + "_wrapper").find('.dataTables_filter'));
        }

        if (typeof dataTable.ajaxUrl != 'undefined') {
            $("#" + dataTable.idTable + "_wrapper").find('.dataTables_filter').find('label').hide();
            $("#" + dataTable.idTable + "_wrapper").find('.row:first-child').find('.col-sm-6:first-child').removeClass('col-sm-6').addClass('col-sm-3');
            $("#" + dataTable.idTable + "_wrapper").find('.row:first-child').find('.col-sm-6').removeClass('col-sm-6').addClass('col-sm-9');
        }
    };

    this.footerCallback = function (row, data, start, end, display) {
        // visualizzo i totali se ci sono e se sono attivati
        var json = dataTable.table.ajax.json();
        if (dataTable.totals && typeof json.totals != 'undefined' && json.totals.length > 0) {
            var $tfoot = this.find('tfoot').length ? this.find('tfoot') : $('<tfoot></tfoot>');

            var $tr = $('<tr></tr>');

            // aggiungo una cella fittizzia per gli inlineButtons
            if (dataTable.inlineButtons)
                $tr.append('<td></td>');

            $.each(json.totals, function (i, v) {
                // risalgo al th della colonna
                var $th = dataTable.$table.find('[data-dt-column-index=' + i + ']');
                var total = $th.attr('data-dt-total');
                var align = $th.attr('data-dt-align');
                var a = 'left';

                if (typeof align != 'undefined' && $.trim(align) != '') {
                    a = align;
                }

                if (typeof total != 'undefined' && $.trim(total) != '') {
                    total = JSON.parse(total);
                    if (typeof total.align != 'undefined')
                        a = total.align;
                }

                var $td = $('<td data-index="' + i + '" style="text-align: ' + a + '; font-size: 15px;"><br><b>' + v + '</b></td>');
                $tr.append($td);
            });

            $tfoot.html($tr);
            $tfoot.insertBefore($(this).find('tbody'));
        }
    };

    this.beforeSuccessEditable = function (data, field, id, columnIndex, rowIndex, col) {
        return true;
    };

    this.afterSuccessEditable = function (data, field, id, columnIndex, rowIndex, col) {
    };

    this.beforeErrorEditable = function (data, field, id, columnIndex, rowIndex, col) {
        return true;
    };

    this.afterErrorEditable = function (data, field, id, columnIndex, rowIndex, col) {
    };

    this.extraParamsEditable = function (index, id, columnIndex, rowIndex, col) {
        return {};
    };

    this.redraw = function (nextFocus) {
        nextFocus = nextFocus || false;

        // prima di fare il redraw, mi salvo quale elemento in questo momento ha il focus così da ripristinarlo
        // in caso sia un elemento all'interno dell'attuale DataTable
        var $focus = $(document.activeElement);
        if (dataTable.table.cell($focus.closest('td')) && nextFocus) {
            dataTable.focusAfterDraw = dataTable.table.cell($focus.closest('td')).index();
        }
        dataTable.table.draw('page');
    };

    this.DTBeforefnCreatedCell = function (td, data, rowData, rowIndex, columnIndex) {
        return true;
    };

    this.DTfnCreatedCell = function (td, data, rowData, rowIndex, columnIndex) {
        if (!dataTable.DTBeforefnCreatedCell(td, data, rowData, rowIndex, columnIndex))
            return;

        var id = rowData[0];

        var col = dataTable.columns[columnIndex];
        if (typeof col.editable != 'undefined' && col.editable && dataTable.editable != false) {
            var index = col.columnIndex;
            var url = col.editable.url || dataTable.ajaxEditUrl + ((typeof dataTable.ajaxUrlParameters != "undefined") ? "&" + dataTable.ajaxUrlParameters : "");
            var spinner = true;
            if (typeof col.editable.spinner != "undefined" && col.editable.spinner == false)
                spinner = false;

            switch (col.editable.type) {
                case 'select':
                    var autocomplete = "";
                    if (col.editable.autocomplete_url) {
                        autocomplete = col.editable.autocomplete_url;
                    } else {
                        autocomplete = $(dataTable.table.column(columnIndex).header()).attr("data-dt-field-url") || dataTable.ajaxUrl;
                        autocomplete += "&op=autocomplete&dt_editing=1&dt_col_index=" + index + (dataTable.ajaxUrlParameters ? "&" + dataTable.ajaxUrlParameters : '');
                    }

                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'select',
                        bind: 'select2:select',
                        id: id,
                        url: url,
                        nullable: ((typeof col.editable.nullable != 'undefined') ? col.editable.nullable : true),
                        nullValue: ((typeof col.editable.null_value != 'undefined') ? col.editable.null_value : null),
                        autocomplete: autocomplete,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(index, data, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'green');
                                    $element.attr('data-old-value', $element.val());
                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'red');
                                    $element.val($element.attr('data-old-value')).trigger('change');
                                }
                            }
                            dataTable.afterSuccessEditable(index, data, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(index, data, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.parent().find(".select2-container--default .select2-selection").css('border-color', 'red');
                                app.error("", "Errore!");
                                $element.val($element.attr('data-old-value')).trigger('change');
                            }
                            dataTable.afterErrorEditable(index, data, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                case 'bool':
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'bool',
                        bind: 'click',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                if (data.response) {
                                    var value = $element.attr("data-value");
                                    if (value === "true") value = false;
                                    if (value === "false") value = true;
                                    $element.attr("data-value", value);
                                    if (value) {
                                        $element.html('<i class="fa fa-check green"></i>');
                                    } else {
                                        $element.html('<i class="fa fa-times red"></i>');
                                    }

                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                var error = "Errore!";
                                if (typeof data.status != 'undefined' &&
                                    data.status == 422 &&
                                    typeof data.responseJSON == 'object' &&
                                    typeof data.responseJSON.value != 'undefined' &&
                                    data.responseJSON.value.length > 0
                                ) {
                                    error = data.responseJSON.value[0];
                                }
                                app.error("", error);
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                case "datetime":
                case "date":
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: col.editable.type,
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw) {
                                        dataTable.redraw(false);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');
                                var error = "Errore!";
                                if (typeof data.status != 'undefined' &&
                                    data.status == 422 &&
                                    typeof data.responseJSON == 'object' &&
                                    typeof data.responseJSON.value != 'undefined' &&
                                    data.responseJSON.value.length > 0
                                ) {
                                    error = data.responseJSON.value[0];
                                }
                                app.error("", error);
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                case 'textarea':
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'textarea',
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');
                                var error = "Errore!";
                                if (typeof data.status != 'undefined' &&
                                    data.status == 422 &&
                                    typeof data.responseJSON == 'object' &&
                                    typeof data.responseJSON.value != 'undefined' &&
                                    data.responseJSON.value.length > 0
                                ) {
                                    error = data.responseJSON.value[0];
                                }
                                app.error("", error);
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
                    break;
                default:
                    new editable({
                        element: $(td),
                        tr: $(td).closest("tr"),
                        type: 'text',
                        bind: 'change',
                        id: id,
                        url: url,
                        index: index,
                        value: $(td).html(),
                        block: col.editable.block,
                        spinner: spinner,
                        mask: col.editable.mask,
                        params: dataTable.extraParamsEditable(index, id, columnIndex, rowIndex, col),
                        done: function (data, $element, $spinner) {
                            if (dataTable.beforeSuccessEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);

                                if (data.response) {
                                    $element.css('border-color', 'green');

                                    if (col.editable.redraw) {
                                        dataTable.redraw(true);
                                    }
                                } else {
                                    app.warning("", data.message);
                                    $element.css('border-color', 'red');
                                }
                            }
                            dataTable.afterSuccessEditable(data, index, id, columnIndex, rowIndex, col);
                        },
                        fail: function (data, $element, $spinner) {
                            if (dataTable.beforeErrorEditable(data, index, id, columnIndex, rowIndex, col)) {
                                $spinner.hide();
                                app.block(0);
                                $element.css('border-color', 'red');
                                var error = "Errore!";
                                if (typeof data.status != 'undefined' &&
                                    data.status == 422 &&
                                    typeof data.responseJSON == 'object' &&
                                    typeof data.responseJSON.value != 'undefined' &&
                                    data.responseJSON.value.length > 0
                                ) {
                                    error = data.responseJSON.value[0];
                                }
                                app.error("", error);
                            }
                            dataTable.afterErrorEditable(data, index, id, columnIndex, rowIndex, col);
                        }
                    }).make();
                    break;
            }
        }
    };

    this.make = function () {
        dataTable.makeColumns();
        dataTable.makeFilters();
        dataTable.makeSorting();
        dataTable.makeCustomButtons();
        dataTable.makeDT();
    };
};